import { Form } from 'react-bootstrap';

export default function CustomAgreePrivacyCheckBox(props) {
    const { name, invalid, invalidText, onChangeCheckBox } = props;
    return (
        <>
            <Form.Group className="mb-3">
                <Form.Check
                    type="checkbox"
                    label={
                        <>
                            <span className="fw-normal">
                                <a href="https://techis.jp/privacy/" target="_blank" rel="noreferrer">
                                    個人情報
                                </a>
                                と、補助金の希望者は
                                <a href="https://techis.jp/reskill/" target="_blank" rel="noreferrer">
                                    リスキル補助金の注意事項
                                </a>
                                に同意する。
                            </span>
                            <span className="text-muted fw-normal">（必須）</span>
                        </>
                    }
                    name={name}
                    onChange={onChangeCheckBox}
                    id={name}
                />
                <Form.Control.Feedback type="invalid" className={invalid && 'd-block'}>
                    {invalidText}
                </Form.Control.Feedback>
            </Form.Group>
        </>
    );
}
